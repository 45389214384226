import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components";
import { Container } from "reactstrap";
import { Link } from "gatsby";
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";

import renAM from "../images/RenAM-500E.jpg";
import bannermetal from "../images/banner/bannerMetal.png"

import ena250 from "../images/printer/ena250.png"
import m650 from "../images/printer/m650.png"
import m450 from "../images/printer/meltiom450.png"

import { FaWhatsapp } from "react-icons/fa";
import ContactBar from "../components/ContactBar";

const BannerSection = styled.div`
  height: 50vh;
  position: relative;
  .bannerImage{
    background-image: url(${bannermetal});
    background-size: cover;
    background-color: #0f0f0f;
    height: 100%;
    // position: relative;
  }
  .bannerText{
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`
const Section = styled.div`
  padding: 60px 0;
`
const PrinterFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  column-gap: 1em;
  .printerBox{
    flex: 1;
    border: 1px solid #ccc;
    img{
      height: 300px;
      object-fit: cover;
    }
    .textArea{
      padding: 10px;
    }
  }
`
const MetalInfoFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  // column-gap: 1em;
  color: #fff;
  text-align: center;
  .leftbox{
    flex: 1;
    padding: 100px 200px;
    background-color: #323232;
  }
  .rightbox{
    flex: 1;
    padding: 100px 200px;
    background-color: #234678;
  }
  p{
    text-align: center;
  }
`
const LinkButton = styled.a`
  color: inherit;
  text-decoration: none;
  text-transform: none;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  :hover{
    text-decoration: none;
    background-color: #fff;
    color: #000;
  }
`
const InfoFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  column-gap: 1em;
  .infoBox{
    flex: 1;
    img{
      height: 300px;
      object-fit: cover;
    }
    .textArea{
      padding: 15px;
    }
  }
`

const MetalPrinters = [
  {
    name: "Renishaw AM500",
    image: renAM,
    description: "Leading gas flow system, precision dynamic control and software for connected digital workflows",
    volume: "250 x 250 x 350",
    highlights: <ul>
      <li>Intelligent gas flow system</li>
      <li>High precision dynamic control</li>
      <li>Smart manufacturing technologies</li>
      </ul>,
  },
  {
    name: "Ermaksan ENA 250",
    image: ena250,
    description: "Overcome complex difficulties in the industrial field and to make easy production of complex geometries",
    volume: "250 x 250 x 300",
    highlights: <ul>
      <li>Fast and precise production</li>
      <li>Low production cost</li>
      <li>eco-friendly with low energy consumption and minimum waste rate</li>
      </ul>,
  },
  {
    name: "EPlus3D EP-M650",
    image: m650,
    description: "Innovative area splicing control technology ensures precise positioning, providing uniformity and stability during the entire printing process.",
    volume: "650 x 650 x 800",
    highlights: <ul>
      <li>Printing of mass-individualized parts in the 340 Liter build chamber.</li>
      <li>Stable Quality, Good Consistency</li>
      <li>Real Time Monitoring, High Security</li>
      </ul>,
  },
  {
    name: "Meltio M450",
    image: m450,
    description: "Affordable, safe metal 3D printer for industrial use. Ideal for small to medium part fabrication and multi-metal research.",
    volume: "145 x 168 x 390",
    highlights: <ul>
      <li>Process utilizing wire, a sealed chamber, and a 3-stage filter.</li>
      <li>Auto toolpath generation & print profiles for seamless experience.</li>
      <li>Achieve low capital and running costs for conventional parts.</li>
      </ul>,
  },
]

const addtionalInfo = [
  {
    title: "Metal Materials",
    description: "Explore our range of high-quality metal materials specifically designed for 3D metal printing. From stainless steel to titanium alloys, our materials ensure precise and durable prints, meeting the most demanding industrial requirements.",
    image: renAM,
    linktitle: "Metal Material",
    link: "/metalmaterial",
  },
  {
    title: "3D Scanning",
    description: "Unlock the full potential of 3D metal printing with our advanced 3D scanning services. Our cutting-edge technology accurately captures complex geometries, enabling seamless integration into your design workflow for precise and custom metal prints.",
    image: renAM,
    linktitle: "metal material",
    link: "/metalmaterial",
  },
  {
    title: "Services",
    description: "From design consultation to post-processing, our comprehensive services cater to all your 3D metal printing needs. Whether you require prototype development or batch production, our expert team ensures exceptional results with unmatched precision and efficiency.",
    image: renAM,
    linktitle: "metal material",
    link: "/metalmaterial",
  },
]

const MetalPrinterPage = () => {
  
  const email = "3dprint@3dgens.com";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    alert("Email Copied to Clipboard")
  };
  return (
    <Layout>
      <SEO title="3D Metal Printer" />
      <BannerSection>
        <div className="bannerImage"></div>
        <div className="bannerText">
          <h3>
            3D Metal Printers
          </h3>
          <p>
          Rethink Metal Part Design And Produce Products, <br/>
          Compenents And Tools With Reduced Weight, <br/>
          Increased Functionalities And <br/>
          Simplified Assemblies.
          </p>
        </div>
      </BannerSection>
      <Container>
        <Section>
          <h2>
            Our Metal Printers
          </h2>
          <PrinterFlexBox>
            {MetalPrinters.map((printer) => (
              <div className="printerBox">
                <img src={printer.image}/>
                <div className="textArea">
                  <h5>{printer.name}</h5>
                  <p>{printer.description}</p>
                  {/* <p>Build Volume: <br/> {printer.volume} mm</p> */}
                  {/* {printer.highlights} */}
                </div>
              </div>
            ))}
          </PrinterFlexBox>
        </Section>
      </Container>
      <Section>
        <MetalInfoFlexBox>
          <div className="leftbox">
            <h4>
              Unlock the Boundless Possibilities of Metal 3D Printing!
            </h4>
            <p>
              Embrace the freedom to design, test, and fabricate metal components beyond the constraints of traditional 
              manufacturing methods. Craft lightweight yet robust structures, unleashing unprecedented capabilities.
            </p>
            {/* <LinkButton href="/product">
              Learn More
            </LinkButton> */}
          </div>
          <div className="rightbox">
            <h4>
              Elevate Your Creations with 3D Metal Printing!
            </h4>
            <p>
              Experience the unparalleled design freedom of Direct Metal Printing (DMP), enabling the creation of lightweight, 
              durable parts that outperform traditional manufacturing methods.
            </p>
            {/* <LinkButton href="/material">
              Learn More
            </LinkButton> */}
          </div>
        </MetalInfoFlexBox>
      </Section>
      <Container>
        {/* <Section>
          <InfoFlexBox>
              {addtionalInfo.map((item) => (
                <div className="infoBox">
                  <img src={item.image}/>
                  <div className="textArea">
                    <h5>
                      {item.title}
                    </h5>
                    <p>
                      {item.description}
                    </p>
                    <LinkButton href={item.link}>
                      {item.linktitle}
                    </LinkButton>
                  </div>
                </div>
              ))}
          </InfoFlexBox>
        </Section> */}
      </Container>
      <ContactBar/>
    </Layout>
  )
}

export default MetalPrinterPage
